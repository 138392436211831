import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import "./sponsors.css"

const Sponsors = () => (
    <div className="sponsors">
        <div>
            <div className="sponsors__wrapper" onClick={() => {
                window.location = 'https://mcdali.ru/'
            }}>
                <StaticImage
                    src="../images/mcdali.png"
                    width={120}
                    quality={95}
                    formats={["auto", "webp", "png"]}
                    alt='Спонсор премии'
                />
            </div>

            <span className="sponsors__text">Спонсор премии</span>
        </div>
        <div>
            <div className="sponsors__wrapper" onClick={() => {
                window.location = 'https://mikron.ru/'
            }}>
                <StaticImage
                    src="../images/mikron2024.png"
                    width={120}
                    quality={95}
                    formats={["auto", "webp", "png"]}
                    alt='Электронные приборы и компоненты'
                />
            </div>

            <span className="sponsors__text">Партнер</span>
        </div>
        <div>
            <div className="sponsors__wrapper" onClick={() => {
                window.location = 'https://miet.ru/'
            }}>
                <StaticImage
                    src="../images/miet.jpg"
                    width={120}
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt='Национальный исследовательский университет "МИЭТ"'
                />
            </div>
            <span className="sponsors__text">Партнер</span>
        </div>
        <div>
            <div className="sponsors__wrapper" onClick={() => {
                window.location = 'https://press-kod.ru/'
            }}>
                <StaticImage
                    src="../images/presscode.png"
                    width={120}
                    quality={95}
                    formats={["auto", "webp", "png"]}
                    alt="Типография в Зеленограде"
                />
            </div>
            <span className="sponsors__text">Партнер</span>
        </div>
    </div>
)

export default Sponsors
