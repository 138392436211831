import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import "./awards.css"

const Awards = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className="awards">
      <div className="awards__wrapper">
        <div
          className="awards__cell"
          style={{
            marginTop: `35px`,
            ...(breakpoints.md && {
              marginTop: `0`,
            }),
          }}
        >
          <div className="awards__point" style={{ marginBottom: `70px` }}>
            <span className="awards__number" style={{ marginLeft: `160px` }}>
              1
            </span>

            <span
              className="awards__img"
              style={{
                paddingLeft: `160px`,
                paddingTop: `35px`,
                left: `-90px`,
                top: `-75px`,
              }}
            >
              <StaticImage
                src="../images/award-triangle.png"
                width={150}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Награда"
              />
            </span>
          </div>
          <p className="awards__text">
            Читатели предлагают
            <br />
            кандидатов в 12 номинациях
          </p>
          <span className="awards__date">17 марта - 12 мая</span>
        </div>
        <div className="awards__desc">
          <h2 className="awards__title">Наградим 12&nbsp;компаний</h2>
          <p className="awards__intro">
            В нашем городе работают сотни компаний. Кому&nbsp;ещё оценивать их
            качество и&nbsp;востребованность&nbsp;в&nbsp;различных отраслях,
            как&nbsp;не самим зеленоградцам.
          </p>
        </div>
        <div
          className="awards__cell"
          style={{
            marginTop: `35px`,
            ...(breakpoints.md && {
              marginTop: `60px`,
            }),

            ...(breakpoints.sm && {
              textAlign: `right`,
            }),
          }}
        >
          <div
            className="awards__point"
            style={{
              ...(breakpoints.md && {
                marginBottom: `70px`,
              }),
            }}
          >
            <span
              className="awards__number"
              style={{
                marginLeft: `38px`,

                ...(breakpoints.sm && {
                  marginLeft: `0`,
                  marginRight: `38px`,
                }),
              }}
            >
              2
            </span>

            <span
              className="awards__img"
              style={{
                left: `-3px`,
                top: `-42px`,

                ...(breakpoints.sm && {
                  left: `auto`,
                  right: `0`,
                }),
              }}
            >
              <StaticImage
                src="../images/award-circle-md.png"
                width={105}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Награда"
              />
            </span>
          </div>
          <p
            className="awards__text"
            style={{
              marginLeft: `90px`,
              marginTop: `36px`,
              maxWidth: `275px`,
              ...(breakpoints.md && {
                marginLeft: `0`,
                marginTop: `0`,
                maxWidth: `100%`,
              }),
            }}
          >
            Формирование шорт-листа из 10 компаний в каждой номинации
          </p>
          <span
            className="awards__date"
            style={{
              marginLeft: `90px`,
              ...(breakpoints.md && {
                marginLeft: `0`,
              }),
            }}
          >
            12 - 14 мая
          </span>
        </div>
        <div
          className="awards__cell"
          style={{
            marginTop: `195px`,
            marginLeft: `115px`,

            ...(breakpoints.md && {
              marginTop: `90px`,
            }),

            ...(breakpoints.sm && {
              marginLeft: `60px`,
            }),
          }}
        >
          <div className="awards__point" style={{ marginBottom: `60px` }}>
            <span className="awards__number">3</span>

            <span
              className="awards__img"
              style={{
                left: `-71px`,
                top: `-55px`,
              }}
            >
              <StaticImage
                src="../images/award-rectangle.png"
                width={170}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Награда"
              />
            </span>
          </div>
          <p className="awards__text">
            Читатели голосуют за&nbsp;лучшие компании
          </p>
          <span className="awards__date">15 - 31 мая</span>
        </div>
        <div
          className="awards__cell"
          style={{ marginTop: `80px`, marginLeft: `50px` }}
        >
          <div className="awards__point" style={{ marginBottom: `60px` }}>
            <span className="awards__number">4</span>

            <span
              className="awards__img"
              style={{
                left: `-41px`,
                top: `-46px`,
              }}
            >
              <StaticImage
                src="../images/award-circle-lg.png"
                width={110}
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Награда"
              />
            </span>
          </div>
          <p className="awards__text" style={{ paddingLeft: `8px` }}>
            Награждение победителей на&nbsp;церемонии
          </p>
          <span className="awards__date" style={{ marginLeft: `8px` }}>
            5 июня
          </span>
        </div>
      </div>
    </div>
  )
}

export default Awards
