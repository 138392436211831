import React, {useState} from "react"
import Modal from "react-modal"
import {StaticImage} from "gatsby-plugin-image"

import "./proposeModal.css"
import Propose from "./propose";
import Auth from "./auth"
import Final from "./final"
import Cats from "../data/cats";

const ProposeModal = ({isOpen, onRequestClose, isSuccess}) => {
    const [category, setCategory] = useState('1')
    const [name, setName] = useState('')
    const [comment, setComment] = useState('')

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [step, setStep] = useState('initial')
    const [verified, setVerified] = useState(false)

    const close = () => {
        setCategory(1)
        setName('')
        setComment('')
        setStep('initial')

        onRequestClose()
    }


    const send = (directly = false) => {
        let categoryObj, categoryName = ''

        categoryObj = Cats.find(item => item.id == category)
        if (categoryObj)
            categoryName = categoryObj.value



        let requestOptions = {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
                'category':categoryName,
                'name': name,
                'comment': comment,
                'email': email,
                'phone': phone,
                'verified': 'y'
            })
        };

        fetch('/set-item', requestOptions)
            .then(response => response.json())
            .then(data => {
                setStep('final')
            });
    }

    const onSend = () => {
        if (!verified)
            setStep('auth')
        else {
            // отправляем запрос
            send()
        }
    }

    const onSetVerified = () => {
        setVerified(true)
        send(true)
    }

    return (
        <Modal
            className="propose-modal"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <div className="propose-modal__wrapper">
                <button className="propose-modal__close" onClick={close}>
                    <StaticImage
                        src="../images/close.png"
                        width={120}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Закрыть"
                    />
                </button>
                <div className="propose-modal__bg">
                    <StaticImage
                        src="../images/propose-bg.png"
                        width={635}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Фон"
                    />
                </div>
                <div className="propose-modal__triangle">
                    <StaticImage
                        src="../images/propose-triangle.png"
                        width={315}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Фон"
                    />
                </div>
                <div className="propose-modal__rectangle">
                    <StaticImage
                        src="../images/propose-rect.png"
                        width={350}
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="Фон"
                    />
                </div>
                {
                    step==='initial' &&
                    <Propose
                        category={category}
                        name={name}
                        comment={comment}
                        onChangeCategory={setCategory}
                        onChangeName={setName}
                        onChangeComment={setComment}
                        onSend={onSend}
                    />
                }
                {
                    step === 'auth' &&
                    <Auth
                        phone={phone}
                        email={email}
                        onSetPhone={setPhone}
                        onSetEmail={setEmail}
                        onSetVerified={onSetVerified}
                    />
                }
                {
                    step === 'final' &&
                    <Final onRequestClose={close} />
                }
            </div>
        </Modal>
    )
}

Modal.setAppElement("#___gatsby")

export default ProposeModal
