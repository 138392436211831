import * as React from "react"
import {useState} from "react"
import {useBreakpoint} from "gatsby-plugin-breakpoints"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Categories from "../components/categories"
import Sponsors from "../components/sponsors"
import Awards from "../components/awards"
import ProposeButton from "../components/proposeButton"
import ProposeModal from "../components/proposeModal"
import VoteModal from "../components/voteModal"
import {YMInitializer} from "react-yandex-metrika"

const IndexPage = () => {
    const breakpoints = useBreakpoint()
    const [isVoteModalOpen, setIsVoteModalOpen] = useState(false)
    const [isProposeModalOpen, setIsProposeModalOpen] = useState(false)
    //const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    return (
        <Layout onRequestVote={setIsProposeModalOpen}>
            <Seo title="Главная"/>

            <Categories/>

            <div
                style={{
                    maxWidth: `1140px`,
                    margin: `0 auto`,
                    padding: breakpoints.md
                        ? `64px 16px 32px 16px`
                        : `70px 16px 130px 16px`,
                    borderBottom: `1px solid #000`,
                    textAlign: `center`,
                }}
            >
                <ProposeButton onClick={() => setIsVoteModalOpen(true)} title='Проголосовать'/>
            </div>

            <Sponsors/>

            <Awards/>

            <VoteModal
                isOpen={isVoteModalOpen}
                onRequestClose={() => setIsVoteModalOpen(false)}
            />

            <YMInitializer accounts={[87633028]}/>
        </Layout>
    )
}

export default IndexPage
