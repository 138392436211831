import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import {useBreakpoint} from "gatsby-plugin-breakpoints"

const Categories = () => {
    const breakpoints = useBreakpoint()

    return (
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 1440,
                padding: `100px 150px 56px 150px`,
                display: `grid`,
                gap: `24px 0`,
                gridTemplateColumns: `repeat(6, 1fr)`,
                textAlign: `center`,

                ...(breakpoints.md && {
                    padding: `32px 16px`,
                    gridTemplateColumns: `repeat(3, 1fr)`,
                    gap: `130px 0`,
                    justifyItems: `center`,
                }),

                ...(breakpoints.sm && {
                    padding: `100px 32px`,
                    gap: `32px`,
                    gridTemplateColumns: `repeat(auto-fill,minmax(200px,1fr))`,
                    gridAutoFlow: `column`,
                    gridAutoColumns: `minmax(200px,1fr)`,
                    overflowX: `auto`,
                }),
            }}
        >
            <div
                style={{
                    position: `relative`,
                    paddingLeft: `22px`,
                    whiteSpace: `nowrap`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                    }),
                }}
            >
        <span
            style={{
                width: `235px`,
                position: `absolute`,
                left: `-8px`,
                top: `-37px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% - 12px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/med.png"
              width={235}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Автосервис
            </div>

            <div
                style={{
                    position: `relative`,
                    paddingLeft: `16px`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        marginTop: `0`,
                    }),
                }}
            >
        <span
            style={{
                width: `100px`,
                position: `absolute`,
                left: `51px`,
                top: `-28px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% - 12px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/bank.png"
              width={100}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Доставка<br/>еды
            </div>

            <div
                style={{
                    marginTop: `-14px`,
                    paddingLeft: `16px`,
                    position: `relative`,
                    textAlign: `left`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        marginTop: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `125px`,
                position: `absolute`,
                left: `-10px`,
                top: `-26px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% - 12px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/shop.png"
              width={125}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Ресторан
            </div>

            <div
                style={{
                    position: `relative`,
                    paddingLeft: `8px`,
                    marginTop: `16px`,
                    textAlign: `left`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        marginTop: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `180px`,
                position: `absolute`,
                left: `-18px`,
                top: `-30px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `calc(50% - 12px)`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/beauty.png"
              width={180}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Салон красоты
            </div>

            <div
                style={{
                    position: `relative`,
                    marginTop: `16px`,
                    paddingLeft: `40px`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        marginTop: `0`,
                        maxWidth: `160px`,
                    }),
                }}
            >
        <span
            style={{
                width: `180px`,
                position: `absolute`,
                left: `21px`,
                top: `-71px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/center.png"
              width={180}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Спортивный центр
            </div>

            <div
                style={{
                    marginTop: `80px`,
                    position: `relative`,
                    textAlign: `right`,

                    ...(breakpoints.md && {
                        marginTop: `0`,
                        paddingLeft: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `200px`,
                position: `absolute`,
                left: `10px`,
                top: `-90px`,

                ...(breakpoints.md && {
                    left: `calc(50% - 15px)`,
                    top: `-15px`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/employer.png"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Магазин
            </div>

            <div
                style={{
                    position: `relative`,
                    paddingRight: `16px`,

                    ...(breakpoints.md && {
                        paddingRight: `0`,
                    }),
                }}
            >
        <span
            style={{
                width: `150px`,
                position: `absolute`,
                left: `17px`,
                top: `-56px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/sport.png"
              width={150}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Медцентр
            </div>

            <div
                style={{
                    position: `relative`,
                    paddingRight: `20px`,
                    paddingTop: `12px`,
                    fontSize: '15px',
                    lineHeight: '20px',

                    ...(breakpoints.md && {
                        paddingRight: `0`,
                        paddingTop: `0`,
                    }),
                }}
            >
        <span
            style={{
                width: `200px`,
                position: `absolute`,
                left: `0`,
                top: `-16px`,

                ...(breakpoints.md && {
                    left: `calc(50% + 24px)`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/food.png"
              width={200}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Образовательный<br />проект
            </div>

            <div
                style={{
                    position: `relative`,
                    paddingTop: `16px`,
                    paddingLeft: `30px`,
                    textAlign: `left`,
                    fontSize: '13px',
                    lineHeight: '16px',

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        paddingTop: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `145px`,
                position: `absolute`,
                left: `-8px`,
                top: `-96px`,

                ...(breakpoints.md && {
                    left: `calc(50% - 12px)`,
                    top: `calc(50% - 18px)`,
                    transform: `translate(-50%, -50%)`,
                }),
            }}
        >
          <StaticImage
              src="../images/complex.png"
              width={145}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Культура и<br />развлечения
            </div>

            <div
                style={{
                    paddingLeft: `8px`,
                    position: `relative`,
                    textAlign: `left`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `280px`,
                position: `absolute`,
                left: `-22px`,
                top: `-42px`,

                ...(breakpoints.md && {
                    left: `calc(50% + 13px)`,
                    top: `calc(50% + 16px)`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% + 5px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/education.png"
              width={280}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Все для животных
            </div>

            <div
                style={{
                    marginTop: `44px`,
                    paddingLeft: `26px`,
                    position: `relative`,

                    ...(breakpoints.md && {
                        paddingLeft: `0`,
                        marginTop: `0`,
                        textAlign: `center`,
                    }),
                }}
            >
        <span
            style={{
                width: `135px`,
                position: `absolute`,
                left: `39px`,
                top: `-36px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% - 16px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/auto.png"
              width={135}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Услуги
            </div>

            <div
                style={{
                    marginTop: `40px`,
                    paddingRight: `8px`,
                    position: `relative`,
                    textAlign: `right`,

                    ...(breakpoints.md && {
                        textAlign: `center`,
                        paddingRight: `0`,
                        marginTop: `0`,
                    }),
                }}
            >
        <span
            style={{
                width: `104px`,
                position: `absolute`,
                right: `-6px`,
                top: `-30px`,

                ...(breakpoints.md && {
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                }),

                ...(breakpoints.sm && {
                    top: `calc(50% - 16px)`,
                }),
            }}
        >
          <StaticImage
              src="../images/person.png"
              width={104}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Категория"
          />
        </span>
                Персона<br/>года
            </div>
        </div>
    )
}

export default Categories
